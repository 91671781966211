import isMobile from 'ismobilejs'

const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.includes('safari/')) return true
    else return false
}
// 
const getQuality = () => {
    let mobile = isMobile(window.navigator).phone
    let tablet = isMobile(window.navigator).tablet
    if (mobile) return "low"
    else if (tablet) return "mid"
    else return "high"
}

const getDevice = () => {
    let mobile = isMobile(window.navigator).phone
    let tablet = isMobile(window.navigator).tablet
    if (mobile) return "mobile"
    else if (tablet) return "tablet"
    else return "desktop"
}

window.MOBILE = isMobile(window.navigator).any
window.PX_RATIO = Math.min(window.devicePixelRatio, 2)
window.IS_SAFARI = isSafari()
window.QUALITY = getQuality()
window.DEVICE = getDevice()

window.PX_RATIO = Math.min(window.devicePixelRatio, 2)
window.SVG_NS = 'http://www.w3.org/2000/svg'
window.VERSION = '1.0'
window.PI =         Math.PI
window.TWO_PI =     Math.PI * 2
window.TAU =        Math.PI * 2
window.HALF_PI =    Math.PI / 2
window.THIRD_PI =   Math.PI / 3
window.QUARTER_PI = Math.PI / 4
window.COMPOSITES = ['color', 'color-burn', 'color-dodge', 'copy', 'darken', 'destination-atop', 'destination-in', 'destination-out', 'destination-over', 'difference', 'exclusion', 'hard-light', 'hue', 'lighten', 'lighter', 'luminosity', 'multiply', 'overlay', 'saturation', 'screen', 'soft-light', 'source-atop', 'source-in', 'source-out', 'source-over', 'xor']
