import Vertex from "@/dom_gl/glsl/vtx.glsl"
import Fragment from "@/dom_gl/glsl/fragments/scroll-parallax.glsl"
// import Fragment from "@/dom_gl/glsl/frag.glsl"
import {
    lerp
} from "three/src/math/MathUtils"
import Img from "./index"
import {
    Mesh,
    ShaderMaterial
} from "three"
import {
    ScrollTrigger
} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class ImgShader extends Img {
    constructor(_img) {
        super(_img)
        this.uScale = 1
        this.uPosY = 0
        this.uGradient = 0.5
        this.uAlpha = .8
        this.lerp = .9
        this.offset_y = 0
        this.material = new ShaderMaterial({
            vertexShader: Vertex,
            fragmentShader: Fragment,
            transparent: true,
            wireframe: false,
            uniforms: {
                uTime: {
                    value: 1.0
                },
                uGradient: {
                    value: this.uGradient
                },
                uTexture: {
                    value: this.texture
                },
                uPosY: {
                    value: this.uPosY
                },
                uScale: {
                    value: this.uScale
                },
                uAlpha: {
                    value: this.alpha
                },
            }
        })
        this.mesh = new Mesh(this.geometry, this.material)
        this.scrollTrigger()
    }

    scrollTrigger() {
        gsap.fromTo(this, {
            uAlpha: .8,
            offset_y: 0,
            uGradient: .5,
            uScale: 1
        }, {
            uAlpha: 0,
            offset_y: 1,
            uGradient: .0,
            uScale: .8,
            scrollTrigger: {
                trigger: this.dom_element,
                start: "top top",
                end: "bottom top",
                scrub: true,
                markers: false
            }
        })
    }

    resize() {
        this.reset()
        const texture = this.reloadTexture()
        this.material.uniforms.uTexture.value = texture
    }

    render(_delta) {
        this.updateUniforms(_delta)
        this.updatePosition(-.5)
    }

    updateUniforms(_delta) {
        this.material.uniforms.uTime.value = _delta
        //
        const opacity = this.material.uniforms.uAlpha.value
        this.material.uniforms.uAlpha.value = lerp(this.uAlpha, opacity, this.lerp)
        //
        const scale = this.material.uniforms.uScale.value
        this.material.uniforms.uScale.value = lerp(this.uScale, scale, this.lerp)
        //
        const gradient = this.material.uniforms.uGradient.value
        this.material.uniforms.uGradient.value = lerp(this.uGradient, gradient, this.lerp)
        //
        const pos_y = this.material.uniforms.uPosY.value
        const _y = (this.top + this.height / 2 - window.HALF_Y) / window.HALF_Y + this.offset_y
        this.material.uniforms.uPosY.value = lerp(_y, pos_y, this.lerp)
    }

}