import BigText from "@/components/big_text"
import {
    TxtShader,
    Txt,
    Link,
    ImgShader,
    ImgZoom,
    Img,
    Video
} from "@/dom_gl/components"
import {
    BLENDER_HEAVY,
    BLENDER_THIN,
    BARLOW
} from "@/dom_gl/fonts/index"

//

const FONT_TITLE = {
    name: 'BlenderPro Heavy',
    source: BLENDER_HEAVY
}
const FONT_SUBTITLE = {
    name: "BlenderPro Thin",
    source: BLENDER_THIN
}
const FONT_FAMILY = {
    name: "Barlow",
    source: BARLOW
}

export default class Page {
    constructor() {
        this.titles = []
        this.videos = []
        this.sliders = []
        this.elements = []
        this.turbo()
        window.event.on('resize', () => this.resize())
        this.resize()
        this.mount(false)
    }

    addEvents() {
        this.openBtn = document.querySelector("#watch-reel-btn")
        if (this.openBtn) {
            this.openBtn.addEventListener("click", () => this.openReel(), false)
        }
        this.closeBtn = document.querySelector("#close-reel-btn")
        if (this.closeBtn) {
            this.closeBtn.addEventListener("click", () => this.closeReel(), false)
        }
    }

    openReel() {
        const reel = document.querySelector("#video-reel")
        reel.currentTime = 0
        gsap.to("#modal-reel", {
            display: "block",
            opacity: 1
        })
    }

    closeReel() {
        gsap.to("#modal-reel", {
            display: "none",
            opacity: 0
        })
    }

    removeEvents() {
        
    }

    show() {
        gsap.delayedCall(1, () => {
            this.titles.forEach((title, i) => {
                title.tl.delay(i * .5 + .65).duration(i * .1 + 2.5).play()
            })
        })
    }

    texts() {
        const titles = document.querySelectorAll('.title-gl')
        titles.forEach(el => {
            const _title = new TxtShader(el, {
                fonts: [FONT_TITLE]
            })
            this.titles.push(_title)
            this.elements.push(_title)
            window["App"].add(_title)
            el.classList.remove("opacity-0")
        })
        const subtitles = document.querySelectorAll('.subtitle-gl')
        subtitles.forEach(el => {
            const _title = new TxtShader(el, {
                fonts: [FONT_SUBTITLE]
            })
            this.titles.push(_title)
            this.elements.push(_title)
            window["App"].add(_title)
        })
        //
        const texts = document.querySelectorAll('.text-gl')
        texts.forEach(el => {
            const _txt = new Txt(el, {
                fonts: [FONT_FAMILY]
            })
            this.elements.push(_txt)
            window["App"].add(_txt)
        })
    }

    marquees() {
        const marquees = document.querySelectorAll('.marquee-text')
        marquees.forEach(text => {
            let obj = new BigText(text)
            this.elements.push(obj)
            window["App"].add(obj)
        })
    }

    resize() {
        this.elements.forEach(resizer => resizer.resize())
    }

    turbo() {
        // https://turbo.hotwired.dev/reference/events
        document.addEventListener("turbo:click", async (event) => this.unmount())
        document.addEventListener("turbo:frame-load", async (event) => this.mount(true))
    }

    setVideos() {

    }

    mount(show) {
        gsap.to("#turbo-frame, canvas[data-engine]", {
            opacity: 1,
            onComplete: () => {
                this.addEvents()
                this.blazy()
                this.setVideos()
                this.marquees()
                this.texts()
                this.mountSliders()
                if (show) this.show()
            }
        })
    }

    unmount() {
        window.ScrollTo(0, 0)
        this.removeEvents()
        gsap.to("#turbo-frame, canvas[data-engine]", {
            opacity: 0,
            onComplete: () => {
                //Destroy sliders
                this.sliders.forEach(slider => slider.destroy(true, true))
                //Destroy resizes
                // this.elements.forEach(resizer => resizer.destroy())
                this.elements.forEach(obj => {
                    window["App"].remove(obj)
                    obj.destroy()
                })
                this.elements = []
                this.titles = []
            }
        })
    }

    blazy() {
        return new Blazy({
            success: (el) => {
                el.parentElement.classList.add("loaded")
                if(el.classList.contains("video-gl")){
                    let _video = new Video(el)
                    window["App"].add(_video)
                    this.elements.push(_video)
                    _video.resize()
                } else if(el.classList.contains("img-gl")) {
                    let _img = new ImgShader(el)
                    window["App"].add(_img)
                    this.elements.push(_img)
                    _img.resize()
                }
            }
        })
    }

    mountSliders() {
        document.querySelectorAll(".hero-slider").forEach(el => {
            let slides = el.dataset.slides || 1
            let slider = new Swiper(el, {
                slidesPerView: slides,
                spaceBetween: 30,
                navigation: {
                    nextEl: el.querySelector(".swiper-button-next"),
                    prevEl: el.querySelector(".swiper-button-prev"),
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                on: {
                    slideChange: () => {
                        window.scroll(window.scrollX, window.scrollY + 1)
                    }
                }
            })
            this.sliders.push(slider)
        })

        document.querySelectorAll(".services-slider").forEach((el) => {
            let slider = new Swiper(el, {
                slidesPerView: 1.35,
                spaceBetween: 15,
                pagination: false,
                centeredSlides: true,
                loop: true,
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 4,
                        spaceBetween: 25,
                    }
                },
                on: {
                    slideChange: () => {
                        window.scroll(window.scrollX, window.scrollY + 1)
                    }
                }
            })
            this.sliders.push(slider)
        })
    }
}