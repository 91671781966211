import {
    Vector2,
    VideoTexture,
    ShaderMaterial,
    Mesh
} from "three"
import {
    lerp
} from "three/src/math/MathUtils"
import Layer from "../components/layer"
import Vertex from "../glsl/vtx.glsl"
import Fragment from '../glsl/fragments/video.glsl'
// import Fragment from '../glsl/fragments/cover.glsl'
import {
    ScrollTrigger
} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class Video extends Layer {
    constructor(_video) {
        super(_video)
        this.uScale = 1
        this.uPosY = 0
        this.uGradient = 0.5
        this.uAlpha = .8
        this.lerp = .9
        this.offset_y = 0
        this.texture = new VideoTexture(this.dom_element)
        this.material = new ShaderMaterial({
            vertexShader: Vertex,
            fragmentShader: Fragment,
            transparent: true,
            wireframe: false,
            uniforms: {
                uTime: {
                    value: 1.0
                },
                uGradient: {
                    value: this.uGradient
                },
                uTexture: {
                    value: this.texture
                },
                uPosY: {
                    value: this.uPosY
                },
                uScale: {
                    value: this.uScale
                },
                uAlpha: {
                    value: this.alpha
                },
                uRatio: {
                    value: new Vector2(this.aspectRatio, 1)
                },
                uSize: {
                    value: new Vector2(this.video_width, this.video_height)
                }
            }
        })
        this.mesh = new Mesh(this.geometry, this.material)
        this.resize()
        this.scrollTrigger()
    }

    scrollTrigger() {
        gsap.fromTo(this, {
            uAlpha: 1,
            offset_y: 0,
            uGradient: .1,
            uScale: 1
        }, {
            uAlpha: 0,
            offset_y: 1,
            uGradient: .0,
            uScale: 1.5,
            scrollTrigger: {
                trigger: this.dom_element,
                start: "top top",
                end: "bottom top",
                scrub: true,
                markers: false
            }
        })
    }

    render(_delta) {
        this.updateUniforms(_delta)
        this.updatePosition(-.5)
    }

    updateUniforms(_delta) {
        this.material.uniforms.uTime.value = _delta
        //
        const opacity = this.material.uniforms.uAlpha.value
        this.material.uniforms.uAlpha.value = lerp(this.uAlpha, opacity, this.lerp)
        //
        const scale = this.material.uniforms.uScale.value
        this.material.uniforms.uScale.value = lerp(this.uScale, scale, this.lerp)
        //
        const gradient = this.material.uniforms.uGradient.value
        this.material.uniforms.uGradient.value = lerp(this.uGradient, gradient, this.lerp)
        //
        const pos_y = this.material.uniforms.uPosY.value
        const _y = (this.top + this.height / 2 - window.HALF_Y) / window.HALF_Y + this.offset_y
        this.material.uniforms.uPosY.value = lerp(_y, pos_y, this.lerp)
    }

    resize() {
        this.reset()
        if (this.width > this.height) {
            this.material.uniforms.uRatio.value.set(this.aspectRatio, 1)
        } else {
            this.material.uniforms.uRatio.value.set(1, this.aspectRatio)
        }
        this.material.uniforms.uSize.value.set(this.width, this.height)
    }

    get video_width() {
        let _width = Number(this.dom_element.dataset.width)
        if (_width) return _width
        else return this.dom_element.videoWidth
    }

    get video_height() {
        let _height = Number(this.dom_element.dataset.height)
        if (_height) return _height
        else return this.dom_element.videoHeight
    }

    get aspectRatio() {
        return this.width / this.height
    }

    get src() {
        return this.dom_element.src
    }

    updatePosition() {
        this.mesh.position.set(this.x, this.y, -1)
    }

}