import {
    PlaneGeometry,
    TextureLoader,
    ImageBitmapLoader,
    MeshBasicMaterial,
    RGBAFormat,
    NearestFilter,
    LinearFilter,
    SRGBColorSpace,
    Texture
} from "three"
import {
    ScrollTrigger
} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
/*
 * @class 
 * @name Layer 
 * @desc Construye un objeto 3d para implementar en three, la base es un objeto del dom, un elemento html
 * @param { HTMLElement } Elemento HTML. *Requerido
 */
export default class Layer {
    constructor(_element, args = {}) {
        if (_element === undefined) throw "HTML element it's required"
        this.dom_element = _element
        this.loader = new TextureLoader()
        // this.loader = new ImageBitmapLoader()
        // texture
        this.texture = new Texture()
        this.renderMode = "on"
        this.offset = {
            x: 0,
            y: 0
        }
        // material
        this.alpha = 1
        this.geometry = new PlaneGeometry(1, 1)
        this.material = new MeshBasicMaterial({
            wireframe: false,
            transparent: true,
            map: this.texture,
            opacity: this.alpha
        })
        this.cof = this.dom_element.dataset.cof || .1
        this.mesh = null
        // this.scrollTrigger()
    }

    scrollTrigger() {
        gsap.fromTo(this, {
            alpha: 1,
            offset_y: 0
        }, {
            alpha: 0,
            offset_y: 1,
            scrollTrigger: {
                trigger: this.dom_element,
                start: "top top",
                end: "bottom center",
                scrub: true,
                markers: false
            }
        })
    }

    updateTexture(_src, callback) {
        this.loader.load(this.src, (texture) => {
            // this.loader.load(`${_src}?${performance.now()}`, (texture) => {
            this.texture = texture
            this.texture.format = RGBAFormat
            this.texture.minFilter = LinearFilter
            this.texture.magFilter = NearestFilter
            this.texture.colorSpace = SRGBColorSpace
            this.texture.generateMipmaps = false
            this.texture.anisotropy = 1
            // Update material texture
            this.material.map = this.texture
            this.material.map.needsUpdate = true
            if (callback) callback(texture)
        })
    }

    reset() {
        this.dom_element.removeAttribute("style")
        this.dom_element.style.opacity = 0
        this.mesh.scale.set(this.width, this.height, 0)
    }

    resize() {
        this.reset()
    }

    render(_delta) {
        this.delta = _delta
        this[this.renderMode]()
    }

    on() {
        this.updatePosition()
    }

    off() {}

    updatePosition(_z = 0) {
        this.mesh.position.set(this.x, this.y, _z)
    }

    get scrollY() {
        return window.scrollY || window.pageYOffset || document.documentElement.scrollTop
    }

    get x() {
        return scrollX - HALF_X + (this.left - scrollX) + this.center.x
    }

    get y() {
        return (scrollY + HALF_Y) - (this.top + scrollY) - this.center.y
    }

    get rect() {
        return this.dom_element.getBoundingClientRect()
    }

    // Radio Aspecto
    get aspectRatio() {
        return this.width / this.height
    }

    get width() {
        return ~~this.rect.width
    }

    get height() {
        return ~~this.rect.height
    }

    get center() {
        return {
            x: this.width / 2,
            y: this.height / 2
        }
    }

    get left() {
        return this.rect.left
    }

    get top() {
        return this.rect.top
    }

    attr(_attr) {
        if (_attr === undefined) throw "El atributo es requerido"
        return window.getComputedStyle(this.dom_element, null).getPropertyValue(_attr)
    }

    destroy() {
        // threejs
        // this.observer.unobserve(this.dom_element)
        // this.observer.disconnect()
        // this.observer = null
        // see: https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
        this.texture.dispose()
        this.geometry.dispose()
        this.material.dispose()
        delete this.mesh
        // html element
        this.dom_element = null
        // attrs
        delete this.dom_element
        delete this.alpha
    }
}