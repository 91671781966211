function ScrollTo(_x = 0, _y = 0) {
    let obj = {
        x: scrollX,
        y: scrollY
    }
    gsap.to(obj, {
        ease: Power2.easeInOut,
        duration: .65,
        x: _x,
        y: _y,
        onUpdate: () => {
            window.scroll(obj.x, obj.y)
        }
    })
}

window["ScrollTo"] = ScrollTo