import {
    gsap,
    Circ,
    Power2
} from "gsap"
export default class Preloader {
    constructor() {
        this.loaded = false
        this.container = document.querySelector("#preloader")
        this.setTL()
    }

    setTL() {
        this.tl = gsap.timeline({
            onReverseComplete: () => this.redirect(),
            paused: true,
            delay: 1
        }).to(this.container, {
            ease: 'power2.out',
            duration: .65,
            display: 'none',
            opacity: 0,
        }, 0).to("#main-header", {
            ease: 'power2.out',
            duration: .45,
            // force3D: true,
            // y: "0%",
            opacity: 1
        }, 1.25)
        // .to('.intro-item', {
        //     ease: "circ.out",
        //     duration: .85,
        //     opacity: 1,
        //     stagger: .035,
        //     y: 0,
        //     force3D: true
        // }, 1.5)
    }

    hide() {
        if (this.loaded) return false
        window.scroll(0,0)
        this.loaded = true
        return this.tl.play()
    }
}