import { Mesh } from "three"
import TxtBase from "./base"

export default class Txt extends TxtBase {
    constructor(txt_lmnt, args = {}) {
        super(txt_lmnt, args)
        this.mesh = new Mesh(this.geometry, this.material)
        this.resize()
    }

    render(_delta) {
        this.delta = _delta
        // this.material.opacity = this.alpha
        this.updatePosition()
    }

}