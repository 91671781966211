import TxtBase from "./base"
import {
    ShaderMaterial,
    Vector4,
    Color,
    DataTexture,
    NearestFilter
} from "three"
//
import Vertex from "@/dom_gl/glsl/vtx_wave.glsl"
import Fragment from "@/dom_gl/glsl/fragments/title.glsl"
// import Vertex from "@/dom_gl/glsl/vtx.glsl"
// import Fragment from "@/dom_gl/glsl/frag.glsl"

// import Fragment from "@/dom_gl/glsl/fragments/marquee.glsl"
import {
    Mesh
} from "three"
export default class TxtShader extends TxtBase {
    constructor(_element, _fonts = []) {
        super(_element, _fonts)
        this.uAlpha = 1
        this.material = new ShaderMaterial({
            vertexShader: Vertex,
            fragmentShader: Fragment,
            uniforms: {
                uTime: {
                    value: 0.0
                },
                uPosY: {
                    value: 3.5
                },
                uProgress: {
                    value: 0.0
                },
                uWave: {
                    value: -3
                },
                uTexture: {
                    value: this.texture
                },
                uAlpha: {
                    value: this.uAlpha
                }
            },
            transparent: true,
            wireframe: false
        })
        this.mesh = new Mesh(this.geometry, this.material)
        this.resize()
        this.setTL()
    }

    setTL() {
        this.tl = new gsap.timeline({
            paused: true
        }).to(this.material.uniforms.uProgress, {
            ease: Power2.easeOut,
            duration: .65,
            value: 1
        }, 0).to(this.material.uniforms.uWave, {
            ease: Power2.easeOut,
            duration: .65,
            value: 0
        }, 0)
        .to(this.material.uniforms.uAlpha, {
            ease: Power2.easeOut,
            duration: .65,
            value: 1
        }, 0)
        .to(this.material.uniforms.uPosY, {
            ease: Power2.easeOut,
            duration: .65,
            value: 0
        }, 0)
    }

    resize() {
        this.reset()
        this.updateTexture(this.src, (texture) => {
            this.material.uniforms.uTexture.value = texture
        })
    }

    render(_delta) {
        this.delta = _delta
        this.material.uniforms.uTime.value = _delta
        // this.material.uniforms.uAlpha.value = this.uAlpha
        this.mesh.position.set(this.x, this.y, 0)
    }

    destroy() {
        // this.observer.unobserve(this.dom_element)
        // this.observer.disconnect()
        // this.observer = null
        this.tl.kill()
        this.tl = null
        // threejs
        // see: https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
        this.texture.dispose()
        this.geometry.dispose()
        this.material.dispose()
        delete this.mesh
        // html element
        this.dom_element = null
        // attrs
        delete this.dom_element
    }

}