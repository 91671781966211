
/**
 * @author JuanFuent.es
 * @name Cursor
 * @desc 
 */

import Mouse from "./mouse"
import { Vector2 } from "three"
import { lerp } from "three/src/math/MathUtils"
export default class Cursor {
    constructor(x, y) {
        this.mouse = new Mouse(x, y)
        //
        this.stroke = "#FFF"
        this.fill = "#000"
        this.radio = 16
        this.diameter = this.radio * 2
        //
        this.domElement = document.createElementNS(SVG_NS, "svg")
        this.circle = document.createElementNS(SVG_NS, "circle")
        this.domElement.appendChild(this.circle)
        document.body.appendChild(this.domElement)
        //
        this.pos = new Vector2(HALF_X, HALF_Y)
        this.precision = 2
        this.scale = 1
        this.degrees = 0
        this.lerp = 0.725
        this.style()
    }

    style() {
        // circle style
        this.circle.setAttribute("stroke", this.stroke)
        this.circle.setAttribute("fill", this.fill)
        this.circle.setAttribute("cx", this.radio)
        this.circle.setAttribute("cy", this.radio)
        this.circle.setAttribute("r", this.radio)
        // dom style. {svg element}
        this.domElement.setAttribute("viewBox", `0 0 ${this.diameter} ${this.diameter}`)
        this.domElement.setAttribute("overflow", "visible")
        this.domElement.setAttribute("width", this.diameter)
        this.domElement.setAttribute("height", this.diameter)
        //
        let style = `border-radius:50%;overflow:hidden;backdrop-filter: blur(1px);position:fixed;z-index:99;left:0;top:0;pointer-events:none;mix-blend-mode:exclusion;`
        this.domElement.setAttribute('style', style)
    }

    speed_morph() {
        const min = 0.3
        const max_distance = 100
        const total = this.dist / max_distance
        return Number(Math.min(total, min).toFixed(2))
    }

    update() {
        const speed_morph = this.speed_morph()
        this.scale += (speed_morph - this.scale) * this.lerp

        this.pos.x = lerp(this.mouse.x, this.x, this.lerp)
        this.pos.y = lerp(this.mouse.y, this.y, this.lerp)

        this.degrees = Math.atan2(this.dy, this.dx) * 180 / Math.PI
    }

    render() {
        this.update()
        this.circle.style.transform = `translate(${this.radio}px,${this.radio}px) rotate(${this.rotation}deg) translate(${-this.radio}px,${-this.radio}px) scale(${1 + this.scale}, ${1 - this.scale})`
        this.domElement.style.transform = `translate3d(${this.x-this.radio}px,${this.y-this.radio}px,0)`
    }

    get x() {
        return this.pos.x.toFixed(this.precision)
    }

    get rotation() {
        return this.degrees.toFixed(this.precision)
    }

    get y() {
        return this.pos.y.toFixed(this.precision)
    }

    get dx() {
        return this.mouse.x - this.pos.x
    }

    get dy() {
        return this.mouse.y - this.pos.y
    }

    get dist() {
        return Math.hypot(this.dx, this.dy)
    }
}