import {
    PlaneGeometry,
    ShaderMaterial,
    NearestFilter,
    LinearFilter,
    CanvasTexture,
    Mesh
} from "three"
import Vertex from "@/dom_gl/glsl/vtx_wave.glsl"
import Fragment from "@/dom_gl/glsl/fragments/marquee.glsl"

export default class Marquee {
    constructor(_element) {
        this.dom_element = _element
        this.texture = null
        this.geometry = new PlaneGeometry(1, 1)
        this.material = new ShaderMaterial()
        this.mesh = new Mesh(this.geometry, this.material)
        this.cof = .1
        this.pos_x = 0
        this.maxWave = 2
        this.speed = Math.random() * .05
        this.intersectionRatio = 0
        this.isIntersecting = true
        this.renderMode = "on"
        this.setObserver()
    }

    setObserver() {
        let options = {
            threshold: [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1],
            rootMargin: "60px 0px"
        }
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                this.intersectionRatio = entry.intersectionRatio
                this.isIntersecting = entry.isIntersecting
                this.renderMode = entry.isIntersecting ? 'on' : 'off'
            })
        }, options)
        this.observer.observe(this.dom_element)
    }

    reset() {
        if (this.texture) this.texture.dispose()
        if (this.material) this.material.dispose()
        this.texture = new CanvasTexture(this.canvas)
        this.texture.minFilter = LinearFilter
        this.texture.magFilter = NearestFilter
        this.material = new ShaderMaterial({
            vertexShader: Vertex,
            fragmentShader: Fragment,
            uniforms: {
                uTime: {
                    value: 0.0
                },
                uPosX: {
                    value: 0.0
                },
                uProgress: {
                    value: 0.0
                },
                uRepeat: {
                    value: this.uRepeat
                },
                uTexture: {
                    value: this.texture
                },
                uWave: {
                    value: 0
                }
            },
            transparent: true,
            wireframe: false
        })
        this.material.map = this.texture
        this.material.map.needsUpdate = true
        //
        this.mesh.geometry = this.geometry
        this.mesh.material = this.material
        this.mesh.scale.set(this.width, this.height, 1)
    }

    render() {
        if (this.auto) {
            this.pos_x += this.speed
        } else {
            this.pos_x += (this.percentScroll - this.pos_x) * this.cof
        }
        if (this.isIntersecting) {
            this.material.uniforms.uWave.value += (((1 - this.intersectionRatio) * this.maxWave) - this.material.uniforms.uWave.value ) * this.cof
            this.material.uniforms.uProgress.value += (this.intersectionRatio - this.material.uniforms.uProgress.value) * this.cof
        }
        this.material.uniforms.uTime.value += .005
        this.material.uniforms.uPosX.value = this.pos_x
        this.mesh.position.set(this.x, this.y, -.5)
    }

    on() {

    }

    off() {

    }

    get scrollY() {
        return window.scrollY || window.pageYOffset || document.documentElement.scrollTop
    }

    get percentScroll() {
        let doc_height = document.body.getBoundingClientRect().height
        return scrollY / (doc_height - HEIGHT)
    }

    get center() {
        return {
            x: this.width / 2,
            y: this.height / 2
        }
    }

    get rect() {
        return this.dom_element.getBoundingClientRect()
    }

    get width() {
        return ~~this.rect.width
    }

    get height() {
        return ~~this.rect.height
    }

    get left() {
        return this.rect.left
    }

    get top() {
        return this.rect.top
    }

    get x() {
        return scrollX - HALF_X + (this.left - scrollX) + this.center.x
    }

    get y() {
        return (scrollY + HALF_Y) - (this.top + scrollY) - this.center.y
    }

    destroy() {
        // threejs
        // see: https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
        this.texture.dispose()
        this.geometry.dispose()
        this.material.dispose()
        delete this.mesh
        this.canvas = null
        this.context = null
        this.text = null
        this.stroked = null
        this.padding = null
        delete this.canvas
        delete this.context
        // html element
        this.dom_element = null
        // attrs
        delete this.dom_element
    }

}