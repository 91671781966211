import Marquee from "./marquee"
export default class BigText extends Marquee {
    constructor(_container) {
        super(_container)
        if (_container === undefined) throw "BigText: El elemento es requerido."
        this.dom_element = _container
        //
        this.canvas = document.createElement('canvas')
        this.context = this.canvas.getContext("2d")
        //
        this.text = this.dom_element.innerText
        this.stroked = this.dom_element.dataset.stroked == "true"
        this.auto = this.dom_element.dataset.auto == "true"
        this.speed = Number(this.dom_element.dataset.speed) || 0.005
        //
        this.padding = 6
        this.resize()
    }

    resize() {
        this.canvas.width = this.text_width + this.padding + (this.height * .35)
        this.canvas.height = this.height
        // this.canvas.style.width = `${~~(this.text_width / PX_RATIO)}px`
        // this.canvas.style.height = `${~~this.height}px`
        this.dom_element.style.color = "transparent"
        //
        this.clear()
        if (this.stroked) this.stroke()
        else this.fill()
        //
        this.reset()
    }

    get text_width() {
        this.context.font = this.fontSize + "px " + this.fontFamily
        return this.context.measureText(this.text).width
    }

    get uRepeat() {
        return (this.width / this.text_width).toFixed(1)
    }

    stroke() {
        this.clear()
        this.context.lineWidth = document.body.classList.contains("mobile") ? 1 : 2
        this.context.strokeStyle = "#FFF"
        this.context.font = this.fontSize + "px " + this.fontFamily
        this.context.strokeText(this.text, this.padding / 2, this.lineHeight * .875)
    }

    fill() {
        this.clear()
        this.context.fillStyle = "#FFF"
        this.context.font = this.fontSize + "px " + this.fontFamily
        this.context.fillText(this.text, this.padding / 2, this.lineHeight * .875)
    }

    attr(_attr) {
        if (_attr === undefined) throw "El atributo es requerido"
        return window.getComputedStyle(this.dom_element, null).getPropertyValue(_attr)
    }

    get color() {
        return this.attr("color")
    }

    get fontFamily() {
        return this.attr("font-family")
    }

    get fontSize() {
        return ~~(this.attr("font-size").replace("px", ""))
    }

    get lineHeight() {
        let lineHeight = this.attr("line-height")
        if (lineHeight == "normal") return ~~((this.fontSize).replace("px", ""))
        else return ~~((lineHeight).replace("px", ""))
    }

    clear() {
        this.context.fillStyle = "black"
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
        // this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

}