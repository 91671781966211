/**
 * @author JuanFuent.es
 * @desc Setup básico de canvas
 */

export default class Canvas {
    constructor() {
        this.canvas = document.createElement("canvas")
        this.context = this.canvas.getContext("2d")
        //
        this.setSize()
    }

    setSize(_width, _height) {
        this.canvas.width = parseInt(_width) * PX_RATIO
        this.canvas.height = parseInt(_height) * PX_RATIO
        // this.canvas.style.width = `${_width}px`
        // this.canvas.style.height = `${_height}px`
    }

    clear() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    stroke(_color = "#000") {
        this.context.strokeStyle = _color
        this.context.stroke()
    }

    bg(_fill= "#000") {
        this.context.fillStyle = _fill
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }

    drawImage(_img, params) {
        this.context.drawImage(_img, params.offsetX, params.offsetY, params.width, params.height)
    }

}