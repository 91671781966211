async function fontAsDataURL(_url) {
    return fetch(_url)
        .then((resp) => resp.ok && resp.blob())
        .then((blob) => new Promise((res) => {
            const reader = new FileReader()
            reader.onload = (evt) => res(reader.result)
            reader.readAsDataURL(blob)
        }))
}

const BLENDER_HEAVY = await fontAsDataURL("/static/BlenderPro-Heavy.woff2")
const BLENDER_THIN = await fontAsDataURL("/static/BlenderPro-Thin.woff2")
const BARLOW = await fontAsDataURL("https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3w-os51os.woff2")

export {
    BLENDER_HEAVY,
    BLENDER_THIN,
    BARLOW
}