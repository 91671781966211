// const EventEmitter = require('events')
import EventEmitter from 'https://cdn.jsdelivr.net/npm/events@3.3.0/+esm'

window.TIME = 0
window.event = new EventEmitter()

function animate() {
    window.TIME = new Date().getTime() 
    requestAnimationFrame(() => animate())
    window.event.emit('animate')
}

animate()