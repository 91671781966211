import { Mesh, ShaderMaterial } from "three"
import Vertex from "../glsl/vtx.glsl"
import Fragment from "../glsl/fragments/gradient.glsl"
import Layer from "./layer"

export default class Background extends Layer {
    constructor(_bg) {
        super(_bg)
        this.setUniforms()
        this.material = new ShaderMaterial({
            vertexShader: Vertex,
            fragmentShader: Fragment,
            transparent: true,
            uniforms: this.uniforms
        })
        this.mesh = new Mesh(this.geometry, this.material)
    }

    setUniforms() {
        this.values = {
            ra: 1.0,
            ga: .17254,
            b1: 1.0,
            rb: .0,
            gb: 1.0,
            bb: 1.0,
        }
        this.uniforms = {
            uTime: {
                value: 1.0
            },
            ra: {
                value: this.values.ra
            },
            ga: {
                value: this.values.ga
            },
            b1: {
                value: this.values.b1
            },
            rb: {
                value: this.values.rb
            },
            gb: {
                value: this.values.gb
            },
            bb: {
                value: this.values.bb
            },
        }
        // let gui = this.gui.addFolder('Gradient')
        // gui.add( this.values, 'ra', .0, 1., .01 )
        // gui.add( this.values, 'ga', .0, 1., .01 )
        // gui.add( this.values, 'ba', .0, 1., .01 )
        // gui.add( this.values, 'rb', .0, 1., .01 )
        // gui.add( this.values, 'gb', .0, 1., .01 )
        // gui.add( this.values, 'bb', .0, 1., .01 )
    }

    resize() {
        this.reset()
    }

    render(_delta) {
        this.delta = _delta
        this.updatePosition()
        this.updateUniforms(_delta)
    }

    updateUniforms(_delta) {
        this.mesh.material.uniforms.uTime.value = performance.now() / 3500
        // this.mesh.material.uniforms.ra.value = this.values.ra
        // this.mesh.material.uniforms.ga.value = this.values.ga
        // this.mesh.material.uniforms.ba.value = this.values.ba
        // this.mesh.material.uniforms.rb.value = this.values.rb
        // this.mesh.material.uniforms.gb.value = this.values.gb
        // this.mesh.material.uniforms.bb.value = this.values.bb
    }
}