import "@/core/constants"
import "@/core/functions"
import "@hotwired/turbo-rails"

import DomGl from "@/dom_gl/dom_gl"
import Credits from '@/components/credits'
import Preloader from "@/components/preloader"
import Page from "@/components/page"
import Cursor from '@/core/ui/cursor'

class App extends DomGl {
    constructor() {
        super()
        this.credits = new Credits("Empack Graphics")
        this.preloader = new Preloader()
        this.page = new Page()
        this.events()
        this.setCursor()
    }

    setCursor() {
        this.cursor = null
        if (document.body.classList.contains("desktop")) {
            this.cursor = new Cursor(HALF_X, HALF_Y)
            window.event.on('animate', () => this.cursor.render())
        }
    }

    events() {
        window.onload = this.loaded()
    }

    loaded() {
        this.page.show()
        return this.preloader.hide()
    }

}

window["App"] = new App()